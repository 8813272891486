// import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { useAppSelector } from "../../redux/store";

interface route {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: route) => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const location = useLocation();
  // const dispatch = useDispatch();

  return (
    <>
      {!isAuth ? (
        <Navigate to="/" replace state={{ path: location.pathname }} />
      ) : (
        children
      )}
    </>
  );
};

export default ProtectedRoute;
