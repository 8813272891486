import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import GoBack from "../../../components/GoBack";
import { handleSteps } from "../../../redux/Auth/Auth";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useState } from "react";
import { handleSetPassword } from "../../../redux/Auth/authAction";

const StepThree = () => {
  const dispatch = useAppDispatch();
  const { steps, data } = useAppSelector((state) => state.auth);
  const [isTogglePassword, setIsTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleStepThree = async () => {
    setLoading(true);
    const credentials = {
      user_id: data?.data?.user_id,
      password: password,
    };
    const res = await dispatch(handleSetPassword(credentials));
    if (res.type !== "handleSetPassword/rejected") {
      dispatch(handleSteps(4));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      {" "}
      <div>
        <div className="flex w-full justify-between items-center pb-[24px]">
          <GoBack
            handleClick={() => {
              dispatch(handleSteps(2));
            }}
          />
          <p className="text-[#3E473F] text-sm font-semibold leading-[18px]">
            {steps}/4
          </p>
        </div>
        <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
          Create your password
        </p>
        <p className="text-disabled text-sm font-normal leading-5 pb-[32px] pt-1 w-[383px]">
          Create your password to secure your account
        </p>

        <div className="flex gap-[20px] pb-3">
          <div className="w-full">
            <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
              Password
            </p>
            <div className="relative">
              <input
                value={password}
                type={`${isTogglePassword ? "text" : "password"}`}
                onChange={(event) => setPassword(event?.target.value)}
                className="rounded-lg w-full focus:border-primary focus:shadow-1xl outline-none px-4 py-3 border bg-white border-[#96A397] bg-transparent text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                placeholder="Password"
              />
              <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                {isTogglePassword ? (
                  <VisibilityOff
                    onClick={() => setIsTogglePassword(!isTogglePassword)}
                    style={{ color: "#96A397", fontSize: "18px" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setIsTogglePassword(!isTogglePassword)}
                    style={{ color: "#96A397", fontSize: "18px" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1 pb-1">
          <div className="">
            <p className="font-medium text-xs leading-[16px] text-[#5C715E] pb-1">
              Your Password Must Contain At Least:
            </p>

            <div className="flex flex-wrap gap-2">
              <div
                className={`${
                  password.length >= 8
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>Eight Characters</p>
                {password.length >= 8 && (
                  <CheckCircle
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
              {/* One Numeric Character */}
              <div
                className={`${
                  /[0-9]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Numeric Character</p>
                {/[0-9]/g.test(password) && (
                  <CheckCircle
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-wrap gap-2 mt-1 mb-6">
              {/* One Lowercase Character */}
              <div
                className={`${
                  /[a-z]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Lowercase Character</p>
                {/[a-z]/g.test(password) && (
                  <CheckCircle
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
              {/* One Uppercase Character */}
              <div
                className={`${
                  /[A-Z]/g.test(password)
                    ? "border-primary text-primary border"
                    : "border-[#96A397] text-[#96A397] border"
                }   flex items-center gap-1 rounded-[4px] py-1 px-2 text-xs  font-medium leading-[16px]`}
              >
                <p>One Uppercase Character</p>
                {/[A-Z]/g.test(password) && (
                  <CheckCircle
                    style={{
                      color: "#2B8C34",
                      fontSize: "14px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <button
          disabled={
            !loading &&
            password.length >= 8 &&
            /[a-z]/g.test(password) &&
            /[A-Z]/g.test(password) &&
            /[0-9]/g.test(password)
              ? false
              : true
          }
          onClick={handleStepThree}
          className="outline-none mb-[32px] w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
        >
          {loading ? "Loading..." : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default StepThree;
