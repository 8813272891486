import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
} from "recharts";
import { CustomTooltip } from "./ToolTip";

interface AreaChartProps {
  data: Record<string, any>[];
  height: number;
}

const OverviewChart: React.FC<AreaChartProps> = ({ data, height }) => {
  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="returningCallers" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#2B8C34" />
              <stop offset="1" stopColor="#2B8C34" />
            </linearGradient>
            <linearGradient id="firstTimeCallers" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#82ca9d" />
              <stop offset="1" stopColor="#82ca9d" />
            </linearGradient>
          </defs>
          <CartesianGrid
            stroke="#F2F4F7"
            // stroke="rgba(242, 244, 247, 0.9)"
            strokeDasharray="3 3"
            vertical={false}
          />
          {/* 
          <ReferenceLine x="dataKeyValue" stroke="red" />
          <ReferenceArea
            x1="startKey"
            x2="endKey"
            y1="minY"
            y2="maxY"
            fill="rgba(0, 0, 0, 0.1)"
          /> */}

          <XAxis
            style={{
              fontSize: "12px",
              fontFamily: "Rethink Sans",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5F6D60",
            }}
            axisLine={false}
            tickLine={false}
            dataKey="name"
          />
          <YAxis
            style={{
              fontSize: "12px",
              fontFamily: "Rethink Sans",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5F6D60",
            }}
            axisLine={false}
            tickLine={false}
            // dataKey="amt"
          />
          <Tooltip content={<CustomTooltip />} />

          <Area
            type="monotone"
            dataKey="returningCallers"
            stroke="#2B8C34"
            strokeWidth={2}
            fillOpacity={0.1}
            fill="url(#returningCallers)"
          />
          <Area
            type="monotone"
            dataKey="firstTimeCallers"
            stroke="#FFC508"
            strokeWidth={2}
            fillOpacity={0.1}
            fill="url(#firstTimeCallers)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverviewChart;
