import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  // getFarmersStats,
  getNewuserCount,
  getOverviewGraph,
  getReturningUsers,
} from "../../redux/Farmers/farmersAction";
import OverviewTable from "../../tables/Overview/Overview";
import OverviewChart from "../../components/Charts/Overview AreaChart/OverviewAreaChart";

const Dashboard = () => {
  const { overviewGraphData, returningUser, newusercount, farmersStats } =
    useAppSelector((state) => state.farmer);
  const dispatch = useAppDispatch();

  const graphData = [
    {
      name: "Jan",
      returningCallers: 4000,
      firstTimeCallers: 2400,
    },
    {
      name: "feb",
      returningCallers: 3000,
      firstTimeCallers: 3400,
    },
    {
      name: "Mar",
      returningCallers: 3000,
      firstTimeCallers: 1398,
    },
    {
      name: "Apr",
      returningCallers: 2000,
      firstTimeCallers: 9800,
    },
    {
      name: "May",
      returningCallers: 2780,
      firstTimeCallers: 3908,
    },
    {
      name: "Jun",
      returningCallers: 1890,
      firstTimeCallers: 4800,
    },
    {
      name: "Jul",
      returningCallers: 2390,
      firstTimeCallers: 3800,
    },
    {
      name: "Aug",
      returningCallers: 3490,
      firstTimeCallers: 4300,
    },
    {
      name: "Sep",
      returningCallers: 390,
      firstTimeCallers: 4800,
    },
    {
      name: "Oct",
      returningCallers: 1290,
      firstTimeCallers: 3800,
    },
    {
      name: "Nov",
      returningCallers: 1090,
      firstTimeCallers: 5800,
    },
    {
      name: "Dec",
      returningCallers: 3590,
      firstTimeCallers: 300,
    },
  ];

  useEffect(() => {
    dispatch(getOverviewGraph());
    dispatch(getReturningUsers());
    dispatch(getNewuserCount());
    // dispatch(getFarmersStats());
  }, [dispatch]);

  console.log(returningUser, newusercount, overviewGraphData, farmersStats);

  return (
    <div>
      <div>
        <Header title="Overview" />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">Farmers</h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">32,342</h4>

          <p className="text-[#225928] text-sm font-medium">
            30%{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>

        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">Calls</h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">32,342</h4>

          <p className="text-[#225928] text-sm font-medium">
            30%{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>

        <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
          <h4 className="text-[#2E332F] font-normal text-sm">
            Avg. call duration (min)
          </h4>

          <h4 className="text-[#2E332F] font-semibold text-xl">20</h4>

          <p className="text-[#225928] text-sm font-medium">
            30%{" "}
            <span className="text-[#5F6D60] text-sm font-normal">
              vs last month
            </span>
          </p>
        </div>
      </div>

      {/* <div className="mt-5 gap-4">
        <div className="bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg">
          <div className="p-4">
            <p className="text-[#2E332F] text-sm ">Farmers Uploaded</p>
            <div className="mt-3">
              <h4 className="text-primary font-semibold text-xl">0</h4>
            </div>
            <div className="mt-1">
              <p className="text-primary text-sm font-medium">
                {farmersStats?.new_farmers?.percentage_increase || 0}%{" "}
                <span className="text-[#5F6D60] text-sm font-normal">
                  vs last month
                </span>
              </p>
            </div>
          </div>

          <div className="mt-4 ml-[-20px] pb-5">
            <AreaChart data={overviewGraphData} height={300} />
          </div>
        </div>
      </div> */}

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4 min-h-[400px]">
        <>
          {/* <div className="grid grid-cols-4 gap-4">
          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#225928] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">Call Volume</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">32,342</h4>
              </div>
              <div className="mt-1">
                <p className="text-[#225928] text-sm font-medium">
                  30%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#3BAC46] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">Returning Users</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">
                  {returningUser || 0}
                </h4>
              </div>
              <div className="mt-1">
                <p className="text-primary text-sm font-medium">
                  {farmersStats?.returning_farmers?.percentage_increase || 0}%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E1E6E1] rounded-lg p-3 flex flex-col justify-between min-h-[130px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="bg-[#72B278] w-[8px] h-[8px] rounded-full"></div>
                <div>
                  <p className="text-[#2E332F] text-sm">First Time User</p>
                </div>
              </div>
              <div className="bg-[#E1E6E1] cursor-pointer w-[24px] h-[24px] flex justify-center items-center rounded-full">
                <NorthEast
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#2E332F",
                  }}
                />
              </div>
            </div>
            <div>
              <div className="">
                <h4 className="text-[#225928] font-semibold text-xl">
                  {" "}
                  {newusercount || 0}
                </h4>
              </div>
              <div className="mt-1">
                <p className="text-[#225928] text-sm font-medium">
                  30%{" "}
                  <span className="text-[#5F6D60] text-sm font-normal">
                    vs last month
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
        </>

        <div className="grid grid-cols-4 gap-4">
          <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
            <div className="flex gap-2 items-center">
              <div className="w-2 h-2 bg-primary rounded-full" />
              <h4 className="text-[#2E332F] font-normal text-sm">
                Returning callers
              </h4>
            </div>

            <h4 className="text-[#2E332F] font-semibold text-xl">
              {returningUser || 0}
            </h4>

            <p className="text-[#225928] text-sm font-medium">
              30%{" "}
              <span className="text-[#5F6D60] text-sm font-normal">
                vs last month
              </span>
            </p>
          </div>

          <div className="p-3 border border-[#E1E6E1] flex flex-col gap-2 bg-white rounded-lg h-fit">
            <div className="flex gap-2 items-center">
              <div className="w-2 h-2 bg-[#FFC508] rounded-full" />
              <h4 className="text-[#2E332F] font-normal text-sm">
                First time caller
              </h4>
            </div>

            <h4 className="text-[##2E332F] font-semibold text-xl">32,342</h4>

            <p className="text-[#225928] text-sm font-medium">
              30%{" "}
              <span className="text-[#5F6D60] text-sm font-normal">
                vs last month
              </span>
            </p>
          </div>
        </div>

        <div className="mt-10">
          <OverviewChart data={graphData} height={400} />
        </div>
      </div>

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4">
        <h4 className="text-[#2E332F] font-normal text-sm">Insight Topics</h4>

        <h4 className="text-[#2E332F] font-semibold text-xl pt-2">20,000</h4>
        <OverviewTable />
      </div>
    </div>
  );
};

export default Dashboard;
