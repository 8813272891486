import { useState } from "react";
import Header from "../../components/Header/Header";
// import FarmerChart from "../../components/Charts/FarmersBarChart";
import ModalComponent from "../../components/Modal/modal";
import Tabs from "../../components/Tabs";
import attach_icon from "../../assets/attach_file_add.svg";
import SearchComponent from "../../components/Search/search";
import filter_icon from "../../assets/filter_icon.svg";
import { Clear } from "@mui/icons-material";
import GoBack from "../../components/GoBack";
import * as XLSX from "xlsx";
import { uploadFarmers } from "../../redux/Farmers/farmersAction";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import FarmersTable from "../../tables/Farmers/FarmersTable";
import { setProgress } from "../../redux/Farmers/farmers";

const Farmers = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);
  const { progress } = useAppSelector((state) => state.farmer);
  // const graphData = [
  //   {
  //     name: "Jan",
  //     pv: 800,
  //     uv: 3000,
  //   },
  //   {
  //     name: "Feb",
  //     pv: 1800,
  //     uv: 2000,
  //   },
  //   {
  //     name: "Mar",
  //     pv: 1000,
  //     uv: 2800,
  //   },
  //   {
  //     name: "Apr",
  //     pv: 600,
  //     uv: 4000,
  //   },
  //   {
  //     name: "May",
  //     pv: 900,
  //     uv: 3000,
  //   },
  //   {
  //     name: "Jun",
  //     pv: 1200,
  //     uv: 2500,
  //   },
  //   {
  //     name: "Jul",
  //     pv: 500,
  //     uv: 1500,
  //   },
  //   {
  //     name: "Aug",
  //     pv: 700,
  //     uv: 2700,
  //   },
  //   {
  //     name: "Sep",
  //     pv: 800,
  //     uv: 2200,
  //   },
  //   {
  //     name: "Oct",
  //     pv: 1190,
  //     uv: 2300,
  //   },
  //   {
  //     name: "Nov",
  //     pv: 1090,
  //     uv: 2000,
  //   },
  //   {
  //     name: "Dec",
  //     pv: 1300,
  //     uv: 1600,
  //   },
  // ];

  const importTabs = [
    { title: "Import from CSV file" },
    { title: "API import" },
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [uploadSteps, setUploadSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failedFarmer, setFailedFarmer] = useState<
    Array<{
      country: string;
      crops: string;
      age: number | string;
      gender: string;
      livestock: string;
      crop: string;
      first_name: string;
      language: string;
      last_name: string;
      livestocks: string;
      phone_number: number | null;
      organization: number | null;
      error: {
        status: number;
        code: string;
        messages: {
          field: string;
          message: string;
          rule: string;
        }[];
      };
    }>
  >([
    {
      country: "",
      crops: "",
      age: 0,
      gender: "",
      livestock: "",
      crop: "",
      first_name: "",
      language: "",
      last_name: "",
      livestocks: "",
      phone_number: null,
      organization: null,
      error: {
        status: 0,
        code: "string",
        messages: [
          {
            field: "",
            message: "",
            rule: "",
          },
        ],
      },
    },
  ]);
  const [farmerInfo, setFarmerInfo] = useState({
    nameOfFile: "",
    noOfCols: 0,
  });
  const [farmers, setFarmers] = useState<
    Array<{
      country: string;
      crops: string;
      age: number | string;
      first_name: string;
      gender: string;

      language: string;
      last_name: string;
      livestocks: string;
      phone_number: number | null;
      organization: number | null;
    }>
  >([
    {
      country: "",
      crops: "",
      age: 0,
      first_name: "",
      language: "",
      gender: "",
      last_name: "",
      livestocks: "",
      phone_number: null,
      organization: null,
    },
  ]);

  // const [activeLanguage, setActiveLanguage] = useState("7D");

  // const handleLanguageChange = (language: string) => {
  //   setActiveLanguage(language);
  // };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  type FileDetails = {
    name: string;
    size: number;
    columns: number;
    rows: number;
    data: object[]; // Array of objects for parsed data
  };

  const [file, setFile] = useState<FileDetails | null>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  // Function to truncate the file name
  const truncateFileName = (name: string, maxLength: number) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "xls") {
        const excelData = await parseExcel(selectedFile); // Get array of objects from the Excel file
        if (excelData) {
          const formattedFarmers = excelData.map((farmer: any) => ({
            country: farmer.country || "",
            state: farmer.state || "",
            crops: farmer.crops || "",
            age: farmer.age,
            farm_size: farmer.farm_size || "",
            first_name: farmer.first_name || "",
            language: farmer.language || "",
            gender: farmer.gender || "",
            last_name: farmer.last_name || "",
            livestocks: farmer.livestocks || "",
            phone_number: farmer.phone_number || null,
            organization: profile?.organization?.id,
          }));

          setFarmers(formattedFarmers);
        }
      }
    }
  };

  const parseExcel = (file: File): Promise<object[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

          const jsonSheet = XLSX.utils.sheet_to_json(firstSheet) as object[]; // Array of objects

          // Check if the sheet has any data
          if (jsonSheet && jsonSheet.length > 0) {
            const rows = jsonSheet.length;
            const columns = Object.keys(jsonSheet[0]).length;

            setFarmerInfo({
              nameOfFile: file.name,
              noOfCols: rows,
            });

            setFile({
              name: file.name,
              size: file.size / 1024, // Convert size to KB
              columns,
              rows,
              data: jsonSheet, // Store the parsed data (array of objects)
            });
          } else {
            // Handle case where the sheet is empty
            setFarmerInfo({
              nameOfFile: file.name,
              noOfCols: 0,
            });

            setFile({
              name: file.name,
              size: file.size / 1024,
              columns: 0,
              rows: 0,
              data: [], // Empty data
            });
          }

          // Resolve the parsed data (array of objects), even if empty
          resolve(jsonSheet);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const droppedFile = event.dataTransfer.files?.[0];
    if (droppedFile) {
      const fileType = droppedFile.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "xls") {
        parseExcel(droppedFile);
      }
    }
  };

  const handleClearFile = () => {
    setFile(null);
  };

  const handleImport = async () => {
    dispatch(setProgress(0));
    setLoading(true);
    setUploadSteps(3);
    const credentials = { farmers: farmers };
    const res = await dispatch(uploadFarmers(credentials));
    if (res?.type === "uploadFarmers/fulfilled") {
      setLoading(false);
      setFarmers([]);
      setUploadSteps(1);
      setIsOpen(false);
      setSuccess(true);
      setFile(null);
    }
    if (res?.type === "uploadFarmers/rejected") {
      if (Object.keys(res?.payload?.response?.data?.data)?.length === 0) {
        setFailedFarmer([]);
        setUploadSteps(3);
      } else {
        setFailedFarmer(res.payload.response.data.data.failedFarmers);
        setUploadSteps(4);
      }

      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        allowOutsideClose={false}
        title={
          uploadSteps === 1 ? (
            "Import Farmers"
          ) : (
            <>
              {success ? null : (
                <GoBack
                  handleClick={() => {
                    setUploadSteps(uploadSteps === 2 ? 1 : 2);
                  }}
                />
              )}
            </>
          )
        }
        subtitle=""
        width="max-w-4xl"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div>
          {uploadSteps === 1 && (
            <div>
              <div className="mt-1">
                <Tabs tabs={importTabs} onTabChange={handleTabChange}></Tabs>
              </div>
              {activeTabIndex === 0 && (
                <>
                  <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className="bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg flex justify-center items-center flex-col min-h-[400px]"
                  >
                    <div className="">
                      <div className="flex justify-center">
                        <div className="bg-[#F6F7F6] border border-[#E1E6E1] w-[56px] h-[56px] rounded-full flex justify-center items-center">
                          <img src={attach_icon} alt="attach" />
                        </div>
                      </div>

                      <div className="mt-2 flex justify-center">
                        <p className="text-[#344335] text-sm">
                          {dragActive
                            ? "Release to upload the file"
                            : "Choose a file or drag and drop the file here"}
                        </p>
                      </div>

                      {!file && (
                        <div className="flex justify-center mt-4">
                          <label className="bg-[#2B8C34] disabled:bg-[#ABB6AC] hover:bg-opacity-[0.9] transition-all rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium cursor-pointer">
                            Choose File
                            <input
                              type="file"
                              onChange={handleFileChange}
                              className="hidden"
                              accept=".csv, .xlsx, .xls" // Only accept CSV and Excel formats
                            />
                          </label>
                        </div>
                      )}

                      {file && (
                        <div className="border mt-3 min-w-[320px] border-[#E1E6E1] flex flex-col bg-white rounded-lg py-3 px-3">
                          <div className="flex gap-2">
                            <div className="bg-[#F6F7F6] border max-w-[250px] border-[#E1E6E1] flex-grow rounded-lg py-2 px-3">
                              <div className="flex justify-between items-center">
                                <p className="text-[#3E473F] text-xs font-medium">
                                  {truncateFileName(file.name, 24)}{" "}
                                  {/* Truncate the file name */}
                                </p>
                                <p className="text-xs text-[#5F6D60]">
                                  {file.size.toFixed(2)} kb
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={handleClearFile}
                              className="bg-[#F6F7F6] max-w-[38px] flex-auto border cursor-pointer border-[#E1E6E1] flex rounded-lg justify-center items-center"
                            >
                              <Clear
                                style={{ color: "#5F6D60", fontSize: "16px" }}
                              />
                            </div>
                          </div>

                          <div className="mt-3 flex justify-between ">
                            <div>
                              <h6 className="text-[#5F6D60] text-xs">
                                Columns found
                              </h6>
                              <p className="text-sm text-[#181B19] font-medium mt-1">
                                {file.columns}
                              </p>
                            </div>
                            <div>
                              <h6 className="text-[#5F6D60] text-xs">
                                Rows found
                              </h6>
                              <p className="text-sm text-[#181B19] font-medium mt-1">
                                {file.rows}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="text-center mt-5">
                        <a href="/Sense template.xlsx" download>
                          <button className="text-[#2b8c34] text-sm font-medium">
                            Download CSV format
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-4">
                    <button
                      disabled={file === null || file.rows === 0}
                      onClick={() => setUploadSteps(2)}
                      className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                    >
                      Continue
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Preview Layout of Csv file uploaded */}
          {uploadSteps === 2 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Import preview
                  </h4>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {farmerInfo?.nameOfFile || "N/A"}
                  </p>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {" "}
                    {farmerInfo?.noOfCols || "N/A"} Rows
                  </p>
                </div>
              </div>

              <div className="overflow-x-auto border-x border-t border-t-[#E1E6E1]   border-x-[#E1E6E1] rounded-lg mt-5">
                <table className="bg-white rounded-lg w-full text-left">
                  <thead className=" bg-[#F6F7F6]  rounded-lg">
                    <tr className="">
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Phone Number
                      </th>
                      {/* <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Gender
                      </th> */}
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Country
                      </th>
                      {/* <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Province
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example row */}
                    {farmers?.map((farmer, index) => {
                      return (
                        <tr key={index} className="border-b border-[#E1E6E1]">
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                            {farmer?.first_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.last_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.phone_number || "Nill"}
                          </td>
                          {/* <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.gender || "Nill"}
                          </td> */}
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.country || "Nill"}
                          </td>
                          {/* <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.state || "Nill"}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end mt-4">
                <button
                  onClick={handleImport}
                  disabled={loading || farmers.length === 0}
                  className="bg-[#2B8C34] disabled:bg-[#ABB6AC] rounded-lg flex gap-2 items-center text-[#FFFFFF] py-3 px-5 text-sm font-medium"
                >
                  {loading ? "Loading..." : "Start Import"}
                </button>
              </div>
            </div>
          )}

          {/*Importing*/}
          {uploadSteps === 3 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Importing
                  </h4>
                </div>
                <div className="bg-[#F6F7F6] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#5F6D60] text-xs font-medium">
                    {farmerInfo?.nameOfFile || "N/A"}
                  </p>
                </div>
              </div>

              <div className="border border-[#E1E6E1] rounded-lg mt-[20px] p-3">
                <div className="flex items-center justify-between">
                  <p className="text-[#5F6D60] font-normal text-xs">
                    Importing...
                  </p>

                  <p className="text-[#5F6D60] font-normal text-xs">
                    {farmerInfo?.noOfCols}
                  </p>
                </div>
                <div className="mt-2 w-full h-[36px] bg-[#F6F7F6] rounded-lg relative overflow-hidden">
                  <div
                    style={{ width: `${progress}%` }}
                    className="h-full bg-primary absolute z-[10] top-0 left-0 flex items-center px-3 rounded-lg transition-width duration-300 ease-in-out"
                  />
                  <div className="h-full bg-[#E1E6E1]  top-0 z-[20] left-0 flex items-center px-3 rounded-lg transition-width duration-300 ease-in-out">
                    <p className="text-white font-medium leading-4 text-xs z-10">
                      Oyo state farmers temporary farmers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*Failed Farmer*/}
          {uploadSteps === 4 && (
            <div>
              <div className="flex mt-4 gap-3 items-center">
                <div>
                  <h4 className="text-[#161D17] font-medium text-lg">
                    Failed upload
                  </h4>
                </div>
                <div className="bg-[#FBB7AB] border border-[#E1E6E1] py-1 px-2 rounded-lg">
                  <p className="text-[#B92020] text-xs font-medium">
                    {failedFarmer?.length || 0} rows
                  </p>
                </div>
              </div>

              <div className="overflow-x-auto w-full border-x border-t border-t-[#E1E6E1]   border-x-[#E1E6E1] rounded-lg mt-5">
                <table className="bg-white overflow-x-auto rounded-lg w-full text-left min-w-[800px]">
                  <thead className=" bg-[#F6F7F6]  rounded-lg">
                    <tr className="">
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Phone Number
                      </th>

                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Age
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Crop
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Livestock
                      </th>

                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
                      >
                        Country
                      </th>
                      <th
                        scope="col"
                        className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px] whitespace-nowrap w-[300px]"
                      >
                        Reason
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example row */}
                    {failedFarmer?.map((farmer, index) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-[#E1E6E1] w-full"
                        >
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                            {farmer?.first_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.last_name || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.phone_number || "Nill"}
                          </td>

                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.age || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.crops || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.livestocks || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                            {farmer?.country || "Nill"}
                          </td>
                          <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize whitespace-normal w-[300px]">
                            {farmer?.error?.messages[0]?.message || "Nill"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </ModalComponent>
      <Header
        title="Farmers"
        btn={
          <>
            <div className="space-x-4">
              <button
                className="px-5 py-[10px] border rounded-lg bg-white outline-primary text-primary text-sm font-normal disabled: hover: focus:"
                onClick={() => setIsOpen(true)}
              >
                Import
              </button>
              <button className="px-5 py-[10px] rounded-lg bg-primary text-white text-sm font-normal disabled: hover: focus:">
                Add Farmer
              </button>
            </div>
          </>
        }
      ></Header>
      <div className="mt-3 h-full">
        <div className="flex justify-between">
          <div className="flex gap-10 items-center">
            <div className="flex flex-col gap-2 py-4 w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4 ">
              <p className="text-[#2E332F] text-sm">Farmers</p>

              <div>
                <h4 className="text-[#2E332F] font-semibold text-2xl">
                  32,342
                </h4>

                <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <div className="bg-[#2B8C34] w-[8px] h-[8px] rounded-full"></div>
                  <div>
                    <p className="text-[#2E332F] text-sm">Calls to NHFA</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <h4 className="text-[#2B8C34] font-semibold text-xl">
                    32,342
                  </h4>
                </div>
                <div className="mt-1">
                  <p className="text-[#2B8C34] text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="flex h-fit items-center cursor-pointer justify-center bg-[#F7F7F7] rounded-[8px] border border-[#ECEFEC]">
            {["7D", "1M", "3M", "1Y"].map((lang, index) => (
              <div
                key={index}
                onClick={() => handleLanguageChange(lang)}
                className={`cursor-pointer px-4 py-3 text-sm font-medium flex items-center justify-center 
                ${
                  activeLanguage === lang
                    ? "bg-white text-[#161D17] border border-[#ECEFEC] rounded-[8px] shadow-2xl" // Active language style
                    : "bg-transparent text-[#5C715E]"
                }`} // Default language style
              >
                <span>{lang}</span>
              </div>
            ))}
          </div> */}
        </div>

        {/* <div className="mt-10">
          <FarmerChart data={graphData} height={400} />
        </div> */}
      </div>
      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4 pt-4 w-full space-y-2">
        <h3 className="text-primary font-semibold text-base py-2">
          Farmers Onboarded
        </h3>
        <div className="flex flex-row gap-4 ">
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              handleChange={handleSearch}
              searchValue={searchValue}
            />
          </div>
          <button
            onClick={() => setIsFilter(!isFilter)}
            className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
          >
            <img src={filter_icon} alt="filtericon" />
            <p>Filter</p>
          </button>
        </div>
        <FarmersTable />
      </div>
    </>
  );
};

export default Farmers;
