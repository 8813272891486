import React, { useState } from "react";
import Header from "../../components/Header/Header";
import SearchComponent from "../../components/Search/search";
import filter_icon from "../../assets/filter_icon.svg";
import CallInsightsTable from "../../tables/CallInsights/CallInsightsTable";

const CallInsights = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  return (
    <div>
      <Header title="Call Insights" />

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4 min-h-[400px]">
        <h4 className="text-[#3E473F] font-medium text-sm">
          Location Breakdown
        </h4>

        {/* TABLE HEADER */}
        <div className="bg-[#F6F7F6] rounded-lg mt-3 p-3 flex gap-3 text-[#5F6D60] text-sm font-medium  ">
          <div className="w-1/3">Location</div>
          <div className="w-1/3">Call Volume</div>
          <div className="w-1/3">Top insight topic</div>
        </div>
        {/* TABLE DATA */}
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <div className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  ">
          <div className="w-1/3">Nairobi</div>
          <div className="w-1/3">1500</div>
          <div className="w-1/3">Harvesting Techniques</div>
        </div>
        <h4 className="text-primary font-medium text-sm cursor-pointer p-3">
          See More
        </h4>
      </div>

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4">
        <div>
          <h4 className="text-[#2E332F] text-sm">Insight Topics</h4>

          <h1 className="text-[#2E332F] text-xl font-semibold mt-2">12,000</h1>
        </div>

        <div className="flex flex-row gap-4 mt-2">
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              handleChange={handleSearch}
              searchValue={searchValue}
            />
          </div>
          <button
            onClick={() => setIsFilter(!isFilter)}
            className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
          >
            <img src={filter_icon} alt="filtericon" />
            <p>Filter</p>
          </button>
        </div>

        <div>
          <CallInsightsTable />
        </div>
      </div>
    </div>
  );
};

export default CallInsights;
