import React, { useState } from "react";
import GoBack from "../../components/GoBack";
import { Link, useNavigate, useParams } from "react-router-dom";
import icon from "../../assets/Message Detail Placeholder Icon.svg";

import { ContentCopy, ForumOutlined } from "@mui/icons-material";
import SearchComponent from "../../components/Search/search";
import toast from "react-hot-toast";
import InsightsChart from "../../components/Charts/InsightsAreaChart copy/InsightsAreaChart";

const CallInsightCategory = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isToggleShowMore, setIsToggleShowMore] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success(`${number} copied to clipboard`, {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-center",
    });
  };

  const graphData = [
    {
      name: "Jan",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "feb",
      uv: 3000,
      pv: 3400,
      amt: 2400,
    },
    {
      name: "Mar",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Apr",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "May",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Jun",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jul",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Aug",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Sep",
      uv: 390,
      pv: 4800,
      amt: 4100,
    },
    {
      name: "Oct",
      uv: 1290,
      pv: 3800,
      amt: 5100,
    },
    {
      name: "Nov",
      uv: 1090,
      pv: 5800,
      amt: 9100,
    },
    {
      name: "Dec",
      uv: 3590,
      pv: 300,
      amt: 5100,
    },
  ];
  return (
    <div>
      <div>
        <GoBack handleClick={() => navigate(-1)} />
      </div>

      <div>
        <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
          <div className="flex justify-between gap-1 items-center py-2 px-3 bg-[#F6F7F6] rounded-t-lg">
            {/* <img src={sense} alt="Sense Logo" className="" /> */}
            <p className="font-medium text-sm leading-[18px] text-[#000] ">
              {category}
            </p>

            <div className="flex gap-2 border border-[#E1E6E1]  px-1 py-2 bg-[#F7F7F7] rounded-lg cursor-pointer">
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                7D
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                1M
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                2M
              </p>
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px] px-2 ">
                1Y
              </p>
            </div>
          </div>
          <div className="bg-[#E1E6E1] h-[1px]"></div>

          <div className="px-3 py-3 bg-white rounded-b-lg">
            <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
              Subcategories
            </p>

            <div className="flex flex-wrap gap-2 mt-3">
              <div
                onClick={
                  () => setSelectedCategory("Best Crop Selection for Region")
                  //   navigate(`/call-insights/${"Best Crop Selection for Region"}`)
                }
                className={`border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer ${
                  selectedCategory === "Best Crop Selection for Region"
                    ? "bg-primary text-white"
                    : "text-[#5F6D60]"
                }`}
              >
                <p className=" text-xs font-medium leading-4 ">
                  Best Crop Selection for Region (320)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Best Crop Selection for Region (320)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Optimal Planting Time (319)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Planting Density & Spacing (318)
                </p>
              </div>

              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Irrigation Techniques & Watering Needs (317)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Best Crop Selection for Region (320)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Optimal Planting Time (319)
                </p>
              </div>
              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Planting Density & Spacing (318)
                </p>
              </div>

              <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                  Irrigation Techniques & Watering Needs (317)
                </p>
              </div>
            </div>
          </div>
        </div>

        {!selectedCategory && (
          <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
            <div className="flex justify-between gap-1 items-center py-3 px-3 bg-[#F6F7F6] rounded-t-lg">
              <p className="font-medium text-sm leading-[18px] text-[#000] ">
                Insight
              </p>
            </div>
            <div className="px-3 py-3 bg-white rounded-b-lg h-[400px] flex justify-center items-center">
              <div className="flex flex-col items-center gap-2">
                <div>
                  <img src={icon} alt="" className="" />
                </div>
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  Select a subcategory to see more insight
                </p>
              </div>
            </div>
          </div>
        )}

        {selectedCategory && (
          <div>
            <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4 ">
              <div className="flex justify-between gap-1 items-center py-3 px-3 bg-[#F6F7F6] rounded-t-lg">
                <p className="font-medium text-sm leading-[18px] text-[#000] ">
                  Best Crop Selection for Region
                </p>
              </div>
              <div className="bg-[#E1E6E1] h-[1px]"></div>
              <div className=" py-3 bg-white rounded-b-lg ">
                <div className="bg-[#FFFFFF]  rounded-lg py-4">
                  <div className="px-4">
                    <p className="text-[#2E332F] text-sm ">Call volume</p>
                    <div className="mt-3">
                      <h4 className="text-primary font-semibold text-xl">
                        320
                      </h4>
                    </div>
                    <div className="mt-1">
                      <p className="text-primary text-sm font-medium">
                        30%{" "}
                        <span className="text-[#5F6D60] text-sm font-normal">
                          vs last month
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="mt-10 pr-6">
                    <InsightsChart data={graphData} height={300} />
                  </div>

                  <div className="mt-8 px-3">
                    <div className="flex border border-[#E1E6E1] rounded-lg w-full bg-white ">
                      <div className="w-[25%] border-r border-l-[#E1E6E1] ">
                        <div className="py-2 border-b border-b-[#E1E6E1] px-2 flex gap-1 items-center mb-2">
                          <ForumOutlined
                            style={{ color: "#2B8C34", fontSize: "22px" }}
                          />
                          <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                            Conversations Summary
                          </p>
                        </div>

                        <div className="pb-3 px-3">
                          <div className="w-full">
                            <SearchComponent
                              style={{ padding: "8px 40px" }}
                              placeholder="Search"
                              handleChange={handleSearch}
                              searchValue={searchValue}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col overflow-y-auto h-[600px]">
                          <div
                            className={`p-4 bg-[#E1F7E3] border-b border-[#E1E6E1] `}
                          >
                            <div className="flex gap-2 items-center pb-1">
                              <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                Abubakar Jesam
                              </p>
                              <div className="bg-[#5C715E] w-[1px] h-[15px]"></div>
                              <p className="text-[#4B4E68] font-normal text-sm leading-[18px]">
                                {" "}
                                +234897590893
                              </p>
                            </div>
                            <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                              Hello, I need help with watering my cro..
                            </p>
                            <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                              12:32 AM | 12 Aug 22
                            </p>
                          </div>

                          <div className={`p-4 border-b border-[#E1E6E1]`}>
                            <div className="flex gap-2 items-center pb-1">
                              <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                                Kola Igbo
                              </p>
                              <div className="bg-[#5C715E] w-[1px] h-[15px]"></div>
                              <p className="text-[#4B4E68] font-normal text-sm leading-[18px]">
                                {" "}
                                +2349084768987
                              </p>
                            </div>
                            <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                              I'm excited to start planning for the upcoming
                              harvest season.
                            </p>
                            <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                              12:32 AM | 12 Aug 22
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="w-[75%] select_scroll">
                        <>
                          {/* EMPTY STATE */}
                          {/* <div className="flex flex-col justify-center items-center h-full w-full">
            <img
              src={dialog}
              alt="dialog icon"
              className="w-[64px] h-[64px] pb-3 "
            />
            <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
              Click on a summary to see the conversation
            </p>
          </div> */}
                        </>

                        <div className="flex pr-3 justify-between items-center">
                          <div className="flex items-center gap-[8px] px-3 py-0 pt-3">
                            {/* FARMER"S INITIALS */}
                            <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                              <p className="font-medium text-base leading-6 text-white ">
                                AJ
                              </p>
                            </div>

                            <div className="">
                              <p className="text-[#344335] font-medium leading-[18px] text-sm">
                                Abubakar Jesam
                              </p>
                              <div
                                className="flex gap-[6px] mt-[4px] cursor-pointer"
                                onClick={() =>
                                  handleCopyNumber("+234897590893")
                                }
                              >
                                <p
                                  className={` text-[#4B4E68]
                  font-normal leading-[18px] text-sm`}
                                >
                                  +234897590893
                                </p>
                                <ContentCopy
                                  className={` text-[#4B4E68]
                  `}
                                  style={{ fontSize: "18px" }}
                                />
                              </div>
                            </div>
                          </div>

                          <Link to={`/farmer-profile/${1}`}>
                            <p className="text-primary cursor-pointer font-medium leading-[18px] text-sm">
                              View Profile
                            </p>
                          </Link>
                        </div>
                        <div className="border-b border-b-[#E1E6E1] flex flex-col gap-1 p-3 w-full px-4 ">
                          <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                            Irrigation & Water Management
                          </p>
                          <p
                            className={`font-normal text-sm leading-[20px] text-[#5F6D60] ${
                              isToggleShowMore ? "" : "truncate"
                            }`}
                          >
                            A smallholder farmer asked NHFA for irrigation
                            advice due to dry conditions. NHFA suggested using a
                            drip irrigation system, offering low-cost setup tips
                            and water-saving techniques like rainwater
                            collection and a solar pump. To assist further, NHFA
                            connected the farmer with a local irrigation expert
                            for technical support.
                          </p>

                          <p
                            onClick={() =>
                              setIsToggleShowMore(!isToggleShowMore)
                            }
                            className="font-medium text-xs cursor-pointer leading-[16px] text-primary"
                          >
                            {isToggleShowMore ? "View less" : " Read more"}
                          </p>
                        </div>

                        <div className="flex flex-col gap-[40px] my-6 p-3 overflow-y-auto w-full scroll h-[500px]">
                          {/* SENDER */}
                          <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                            <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                              Hello, I need help with watering my crops. It's
                              been dry for weeks.
                            </p>
                          </div>
                          {/* RECEIVER */}
                          <div className="flex justify-end items-end">
                            <div className="rounded-lg border bg-[#F2FBF3] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                              <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                Hello! I can help you with irrigation tips. What
                                crops are you growing, and how large is your
                                farm?
                              </p>
                            </div>
                          </div>
                          {/* SENDER */}
                          <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                            <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                              Hello, I need help with watering my crops. It's
                              been dry for weeks.
                            </p>
                          </div>
                          {/* RECEIVER */}
                          <div className="flex justify-end items-end">
                            <div className="rounded-lg border bg-[#F2FBF3] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                              <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                Hello! I can help you with irrigation tips. What
                                crops are you growing, and how large is your
                                farm?
                              </p>
                            </div>
                          </div>
                          {/* SENDER */}
                          <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                            <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                              Hello, I need help with watering my crops. It's
                              been dry for weeks.
                            </p>
                          </div>
                          {/* RECEIVER */}
                          <div className="flex justify-end items-end">
                            <div className="rounded-lg border bg-[#F2FBF3] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                              <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                                Hello! I can help you with irrigation tips. What
                                crops are you growing, and how large is your
                                farm?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallInsightCategory;
