import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  getFarmersByOrganization,
  getFarmersStats,
  getNewuserCount,
  getOverviewGraph,
  getReturningUsers,
  uploadFarmers,
} from "./farmersAction";

type FarmerState = {
  loading: boolean;
  overviewGraphData: {
    month: string;
    farmer_count: number;
  }[];
  returningUser: string;
  newusercount: string;
  farmersStats: {
    new_farmers: {
      count: number | null;
      percentage_increase: number | null;
    };
    returning_farmers: {
      count: number | null;
      percentage_increase: number | null;
    };
  };
  progress: number;
  farmers: {
    id: string;
    created: string;
    updated: string;
    firstName: string;
    lastName: string;
    age: number;
    phoneNumber: string;
    language: string;
    country: string;
    organizationIdId: string;
  }[];
  farmersTotal: number;
  farmersLoading: boolean;
};

type FarmerData = {
  month: string;
  farmer_count: number;
};

const initialState: FarmerState = {
  loading: false,
  overviewGraphData: [],
  returningUser: "",
  newusercount: "",
  progress: 0,
  farmersStats: {
    new_farmers: {
      count: 0,
      percentage_increase: 0,
    },
    returning_farmers: {
      count: 0,
      percentage_increase: 0,
    },
  },
  farmers: [],
  farmersTotal: 0,
  farmersLoading: false,
};

// Configure Redux Persist
const farmerPersistConfig = {
  key: "farmer",
  storage,
  blacklist: ["loading", "progress"],
  // Add any additional configuration options as needed
};

export const farmerSlice = createSlice({
  name: "farmer",
  initialState,
  reducers: {
    logOut: () => {
      localStorage.setItem("token", "");
      localStorage.clear();

      return initialState;
    },

    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    // login user
    builder.addCase(uploadFarmers.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(
      uploadFarmers.fulfilled,
      (state, action: PayloadAction<any>) => {
        // state.loading = false;
      }
    );
    builder.addCase(
      uploadFarmers.rejected,
      (state, action: PayloadAction<any>) => {
        // state.loading = false;
      }
    );

    // overview data
    builder.addCase(getOverviewGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getOverviewGraph.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.data;
        const formatMonth = (monthString: string): string => {
          const [year, month] = monthString.split("-");
          const monthMap: { [key: string]: string } = {
            January: "Jan",
            February: "Feb",
            March: "Mar",
            April: "Apr",
            May: "May",
            June: "Jun",
            July: "Jul",
            August: "Aug",
            September: "Sep",
            October: "Oct",
            November: "Nov",
            December: "Dec",
          };
          return `${monthMap[month]}(${year})`;
        };

        const formattedData: FarmerData[] = payloadData?.map((item: any) => ({
          month: formatMonth(item.month),
          farmer_count: item.farmer_count,
        }));

        state.overviewGraphData = formattedData;
      }
    );
    builder.addCase(
      getOverviewGraph.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // returning user
    builder.addCase(getReturningUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getReturningUsers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.returning_farmers;

        state.returningUser = payloadData;
      }
    );
    builder.addCase(
      getReturningUsers.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // new user count
    builder.addCase(getNewuserCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getNewuserCount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload?.new_farmers;

        state.newusercount = payloadData;
      }
    );
    builder.addCase(
      getNewuserCount.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // farmers stats
    builder.addCase(getFarmersStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getFarmersStats.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        const payloadData = action.payload;

        state.farmersStats = payloadData;
      }
    );
    builder.addCase(
      getFarmersStats.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
      }
    );

    // get all faremre
    builder.addCase(getFarmersByOrganization.pending, (state) => {
      state.farmersLoading = true;
    });
    builder.addCase(
      getFarmersByOrganization.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.farmersLoading = false;
        const payloadData = action.payload;
        state.farmers = payloadData.data;
        state.farmersTotal = payloadData.meta.total;
      }
    );
    builder.addCase(
      getFarmersByOrganization.rejected,
      (state, action: PayloadAction<any>) => {
        state.farmersLoading = false;
      }
    );
  },
});

const farmerReducer = persistReducer(farmerPersistConfig, farmerSlice.reducer);
export const { logOut, setProgress } = farmerSlice.actions;

export default farmerReducer;
