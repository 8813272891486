import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { jwtDecode } from "jwt-decode";
import {
  createCompany,
  getAllCountries,
  getStateByCountryID,
  handleLogin,
  registerUser,
  userForgotPassword,
  userResetPassword,
} from "./authAction";

type AuthState = {
  isAuth: boolean;
  loading: boolean;
  token: string;
  refreshToken: string;
  steps: number;
  email: string;
  data: any;
  countries: { id: number; name: string }[];
  countriesLoading: boolean;
  state: { id: number; name: string }[];
  stateLoading: boolean;
  profile: any;
  companyInfo: {
    admin_id: number;
    organization_id: number;
  };
};

const initialState: AuthState = {
  isAuth: false,
  loading: false,
  token: "",
  refreshToken: "",
  steps: 1,
  email: "",
  data: [],
  countries: [],
  countriesLoading: false,
  state: [],
  stateLoading: false,
  profile: {},
  companyInfo: {
    admin_id: 0,
    organization_id: 0,
  },
};

// Configure Redux Persist
const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "countriesLoading", "stateLoading"],
  // Add any additional configuration options as needed
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isAuth = false;
      localStorage.setItem("token", "");
      localStorage.setItem("refreshToken", "");
      localStorage.clear();
      sessionStorage.clear();
    },
    logIn: (state, action: PayloadAction<string>) => {
      state.isAuth = true;
    },
    handleSteps: (state, action: PayloadAction<number>) => {
      state.steps = action.payload;
    },
    handleEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    // login user
    builder.addCase(handleLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      handleLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuth = true;
        localStorage.setItem("token", action.payload.data.access);
        localStorage.setItem("refreshToken", action.payload.data.refresh);
        state.token = action.payload.data.access;
        state.refreshToken = action.payload.data.refresh;
        const decodedUserInfo = jwtDecode<any>(action.payload.data.access);
        // console.log(decodedUserInfo);
        state.profile = decodedUserInfo;
        console.log(decodedUserInfo);
        // state.is_2fa_enabled = decodedUserInfo.is_2fa_enabled;
        // state.otp_method = decodedUserInfo.otp_method;
      }
    );
    builder.addCase(handleLogin.rejected, (state) => {
      state.loading = false;
    });

    // forgot password
    builder.addCase(userForgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userForgotPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userForgotPassword.rejected, (state) => {
      state.loading = false;
    });

    // user reset password
    builder.addCase(userResetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userResetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userResetPassword.rejected, (state) => {
      state.loading = false;
    });

    // REGISTER USER
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      registerUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(registerUser.rejected, (state) => {
      state.loading = false;
    });

    // COUNTRIES
    builder.addCase(getAllCountries.pending, (state) => {
      state.countriesLoading = true;
    });
    builder.addCase(
      getAllCountries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countriesLoading = false;
        state.countries = action.payload.data;
      }
    );
    builder.addCase(getAllCountries.rejected, (state) => {
      state.countriesLoading = false;
    });

    // STATES
    builder.addCase(getStateByCountryID.pending, (state) => {
      state.stateLoading = true;
    });
    builder.addCase(
      getStateByCountryID.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.stateLoading = false;
        state.state = action.payload.data;
      }
    );
    builder.addCase(getStateByCountryID.rejected, (state) => {
      state.stateLoading = false;
    });

    // CREATE COMPANY
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createCompany.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;

        state.companyInfo = action.payload.data;
      }
    );
    builder.addCase(createCompany.rejected, (state) => {
      state.loading = false;
    });
  },
});

const authReducer = persistReducer(authPersistConfig, authSlice.reducer);
export const { logOut, logIn, handleSteps, handleEmail } = authSlice.actions;

export default authReducer;
