import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl, baseUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import { setProgress } from "./farmers";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type organizationType = {
  id: number;
  page: number;
};

export const uploadFarmers = createAsyncThunk(
  "uploadFarmers",
  async (credentials: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${baseUrl}upload-farmers`,
        {
          ...credentials,
        },
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              dispatch(setProgress(percentCompleted));
            }
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        toast.success("Farmers have been uploaded successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else if (error.response && error.response.status === 422) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to import farmers", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getFarmersByOrganization = createAsyncThunk(
  "getFarmersByOrganization",
  async ({ id, page }: organizationType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${baseUrl}farmers/${id}?page=${page}&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while fetching farmers", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getOverviewGraph = createAsyncThunk(
  "getOverviewGraph",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/uploads/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to sign in", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getReturningUsers = createAsyncThunk(
  "getReturningUsers",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/returning-farmers-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to get data", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getNewuserCount = createAsyncThunk(
  "getNewuserCount",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}farmers/new-user-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to get data", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getFarmersStats = createAsyncThunk(
  "getFarmersStats",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}organization/overview/farmer-stats/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to get data", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);
