import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import {
  CreateCompanyCredentials,
  ForgotCredentials,
  LoginCredentialsTypes,
  RegisterUserCredentials,
  ResetCredentials,
  SetPasswordCredentials,
  stateCredentials,
  VerificationCodeCredentials,
} from "./AuthTypes";

// const getToken = () => {
//   const token = localStorage.getItem("token");
//   return token;
// };

export interface LoginApiResponse {
  // Define the properties you expect in the API response
  token: string;
  // Add other properties as needed
  profile: {
    id: string;
    username: string;
    email: string;
    isVerified: boolean;
    role: string;
    firstname: string;
    lastname: string;
    isEnabled: boolean;
  };
}

export const userForgotPassword = createAsyncThunk(
  "forgot",
  async (credentials: ForgotCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/reset-password/notification/`,
        {
          email: credentials.email,
        }
      );
      if (res.status === 200) {
        toast.success(
          "Please check your email for the reset instructions sent",
          {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "top-right",
          }
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Please check that the credentials entered is valid!",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      } else {
        toast.error("Please check that the credentials entered is valid!", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const userResetPassword = createAsyncThunk(
  "reset",
  async (credentials: ResetCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/reset-password/`, {
        user_id: credentials.user_id,
        code: credentials.code,
        password: credentials.password,
      });
      if (res.status === 201) {
        toast.success(
          res.data.message
            ? res.data.message
            : "Your password has been changed successfully, Kindly login again!",
          {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "top-right",
          }
        );
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Oops, looks like the link has either expired or is invalid, please request again.",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      } else {
        toast.error(
          "Oops, looks like the link has either expired or is invalid, please request again.",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "registerUser",
  async (credentials: RegisterUserCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/register-user/`, {
        ...credentials,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success(
          "Your verification confirmation code has been sent. Check yur mail",
          {
            style: {
              border: "1px solid #2B8C34",
              backgroundColor: "#2B8C34",
              color: "#FFFFFF",
              fontSize: 14,
            },
            position: "top-right",
          }
        );
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Something went wrong while trying to create your account",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const confirmationCode = createAsyncThunk(
  "confirmationCode",
  async (credentials: VerificationCodeCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/verify-code/`, {
        ...credentials,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Verified successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.errors, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Something went wrong while trying to verify your account",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const handleSetPassword = createAsyncThunk(
  "handleSetPassword",
  async (credentials: SetPasswordCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/set-password/`, {
        ...credentials,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Password set successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else {
          toast.error(error?.response?.data?.errors, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      } else {
        toast.error("Something went wrong while trying to set your passport", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const createCompany = createAsyncThunk(
  "createCompany",
  async (credentials: CreateCompanyCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/register-organization/`, {
        ...credentials,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Your company workspace has been set up successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else {
          toast.error(error?.response?.data?.errors, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      } else {
        toast.error(
          "Something went wrong while trying to set up your company",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "getAllCountries",
  async (args, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${apiUrl}location/countries/`);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else {
          toast.error(error?.response?.data?.errors, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      } else {
        toast.error("Something went wrong", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const getStateByCountryID = createAsyncThunk(
  "getStateByCountryID",
  async (value: stateCredentials, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${apiUrl}location/states?country_id=${value.id}`
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        } else {
          toast.error(error?.response?.data?.errors, {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          });
          return rejectWithValue(error);
        }
      } else {
        toast.error("Something went wrong", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);

export const handleLogin = createAsyncThunk(
  "handleLogin",
  async (credentials: LoginCredentialsTypes, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/login/`, {
        username: credentials.username,
        password: credentials.password,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Signed In Successfully!", {
          style: {
            border: "1px solid #2B8C34",
            backgroundColor: "#2B8C34",
            color: "#FFFFFF",
            fontSize: 14,
          },
          position: "top-right",
        });
        return res.data;
      }
    } catch (error: any) {
      if (
        (error.response && error.response.status === 400) ||
        (error.response && error.response.status === 401)
      ) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error("Something went wrong while trying to sign in", {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      }
    }
  }
);
