import GoBack from "../../components/GoBack";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { ContentCopy, ForumOutlined, DateRange, Search } from "@mui/icons-material";
import sense from "../../assets/sense.svg";
import toast from "react-hot-toast";
import { useState } from "react";
import { Tooltip } from "@mui/material";

const FarmerProfile = () => {
  const navigate = useNavigate();
  const [isToggleShowMore, setIsToggleShowMore] = useState(false);

  const [typeId, setTypeID] = useState(1);
  const type = [
    {
      id: 1,
      title: "Profile",
    },
    {
      id: 2,
      title: "Insights",
    },
  ];

  const toggleType = (id: number) => {
    setTypeID(id);
  };

  const handleCopyNumber = (number: string) => {
    navigator.clipboard.writeText(number);
    toast.success(`${number} copied to clipboard`, {
      style: {
        border: "1px solid #2B8C34",
        backgroundColor: "#2B8C34",
        color: "#FFFFFF",
        fontSize: 14,
      },
      position: "top-center",
    });
  };
  return (
    <div>
      <div className="p-3">
        <GoBack handleClick={() => navigate(-1)} />
      </div>

      <div>
            <Header title="Farmer account">
                <div className='mt-4'>
                    <ul className="flex gap-4 items-center">
                    {type.map((val) => (
                        <li
                        key={val.id}
                        onClick={() => toggleType(val.id)}
                        className="cursor-pointer"
                        >
                        <p
                            className={
                            typeId === val.id
                                ? "text-primary text-sm font-medium border-b-2 border-primary"
                                : "text-[#96A397] text-sm font-medium"
                            }
                        >
                            {val.title}
                        </p>
                        </li>
                    ))}
                    </ul>
                </div>
            </Header>
      </div>

      {
        typeId === 1 &&
        <>
      
      <div className="mb-5">
        <div className="">
          <div className="">
            <Header
              title="Farmer Profile"
              btn={
                <div>
                  <p className="text-primary cursor-pointer font-medium leading-[18px] text-sm">
                    Edit Profile
                  </p>
                </div>
              }
            >
              <div className="flex items-center gap-[8px]">
                {/* FARMER"S INITIALS */}
                <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex flex-col items-center justify-center">
                  <p className="font-medium text-base leading-6 text-white ">
                    AJ
                  </p>
                </div>

                <div className="py-6">
                  <p className="text-[#344335] font-medium leading-[18px] text-sm">
                    Abubakar Jesam
                  </p>
                  <div
                    className="flex gap-[6px] mt-[4px] cursor-pointer"
                    onClick={() => handleCopyNumber("+234897590893")}
                  >
                    <p
                      className={` text-[#4B4E68]
                  font-normal leading-[18px] text-sm`}
                    >
                      +234897590893
                    </p>
                    <ContentCopy
                      className={` text-[#4B4E68]
                  `}
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-y-4">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Gender
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      Male
                    </p>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Crop
                    </p>
                    <div>
                      <div className="flex gap-2 items-center">
                        <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                          Maize, Cassava
                        </p>

                        <Tooltip
                          // style={{ background: "#000" }}
                          title={
                            <div className="p-2 flex justify-center items-center">
                              <p className="font-semibold text-xs leading-[16px] text-white">
                                Maize, Cassava, Corn, Goat, Cow
                              </p>
                            </div>
                          }
                          arrow
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 8], // Adjusts tooltip position if needed
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#000", // Tooltip background color
                                borderRadius: "8px", // Tooltip border radius
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#000", // Arrow color to match background
                              },
                            },
                          }}
                        >
                          <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                            +3
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Livestock
                  </p>
                  <div>
                    <div className="flex gap-2 items-center">
                      <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                        Cow, Goat
                      </p>

                      <Tooltip
                        // style={{ background: "#000" }}
                        title={
                          <div className="p-2 flex justify-center items-center">
                            <p className="font-semibold text-xs leading-[16px] text-white">
                              Maize, Cassava, Corn, Goat, Cow
                            </p>
                          </div>
                        }
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8], // Adjusts tooltip position if needed
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#000", // Tooltip background color
                              borderRadius: "8px", // Tooltip border radius
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#000", // Arrow color to match background
                            },
                          },
                        }}
                      >
                        <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                          +3
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Country
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Kenya
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    State
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    District
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Date of Birth
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      12 Dec 1963
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Language
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      English
                    </p>
                  </div>
                </div>

                
              </div>
              

              {/* <div className="grid grid-cols-3 gap-6 items-center pb-2">
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Gender
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      Male
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Crop
                    </p>
                    <div>
                      <div className="flex gap-2 items-center">
                        <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                          Maize, Cassava
                        </p>

                        <Tooltip
                          // style={{ background: "#000" }}
                          title={
                            <div className="p-2 flex justify-center items-center">
                              <p className="font-semibold text-xs leading-[16px] text-white">
                                Maize, Cassava, Corn, Goat, Cow
                              </p>
                            </div>
                          }
                          arrow
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 8], // Adjusts tooltip position if needed
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#000", // Tooltip background color
                                borderRadius: "8px", // Tooltip border radius
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#000", // Arrow color to match background
                              },
                            },
                          }}
                        >
                          <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                            +3
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Livestock
                  </p>
                  <div>
                    <div className="flex gap-2 items-center">
                      <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                        Cow, Goat
                      </p>

                      <Tooltip
                        // style={{ background: "#000" }}
                        title={
                          <div className="p-2 flex justify-center items-center">
                            <p className="font-semibold text-xs leading-[16px] text-white">
                              Maize, Cassava, Corn, Goat, Cow
                            </p>
                          </div>
                        }
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8], // Adjusts tooltip position if needed
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#000", // Tooltip background color
                              borderRadius: "8px", // Tooltip border radius
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#000", // Arrow color to match background
                            },
                          },
                        }}
                      >
                        <div className="flex justify-center items-center cursor-pointer bg-primary text-white px-1 py-[2px] text-xs font-medium leading-4 rounded-[4px] border border-primary">
                          +3
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    Country
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Kenya
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    State
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                    District
                  </p>
                  <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                    Nairobi
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Date of Birth
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      12 Dec 1963
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex flex-col gap-1">
                    <p className="font-normal text-sm leading-[18px] text-[#7C8D7E] ">
                      Language
                    </p>
                    <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                      English
                    </p>
                  </div>
                </div>
              </div> */}
            </Header>
          </div>
        </div>
      </div>

        <div className="flex gap-2 items-center py-3 px-3 border border-[#ECEFEC] bg-[#FFFFFF] rounded-lg">
                <img src={sense} alt="Sense Logo" className="" />
                <p className="font-semibold text-base text-[#344335]">
                  Profile Insights
                </p>
        </div>

        <div className="border  border-[#E1E6E1] bg-white rounded-lg my-4">
              <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                <p className="font-medium text-sm text-[#3E473F]">
                Profile Summary
                </p>
              </div>
              <div className="bg-[#E1E6E1] h-[1px]"></div>

              <div className="px-3 py-3 bg-white rounded-b-lg">
                <p className="font-normal text-sm text-[#3E473F] ">
                  A smallholder farmer faces challenges with inconsistent water
                  supply due to reliance on rain-fed farming. This
                  unpredictability, especially during dry seasons, puts maize and
                  vegetable crops at risk. With a limited budget, the farmer
                  struggles to implement a reliable irrigation system, making
                  water management a constant concern.
                  <p />
                  <p className="font-normal text-sm leading-[20px] text-[#3E473F] pt-3"></p>
                  The primary goal is to install an affordable and efficient
                  irrigation solution to ensure a consistent water supply and
                  improve crop yields. To achieve this, the farmer needs access to
                  low-cost drip irrigation systems, expert guidance for setup, and
                  alternative water sources like rainwater harvesting or
                  solar-powered pumps. Additionally, localized weather updates and
                  advice on managing water scarcity are essential for the farm’s
                  success.
                </p>
              </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
                <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                    Farm Characteristics
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                      
                      <div>
                          <h6 className="text-sm text-[#5F6D60]">Location details</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Central Valley</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Farm size</h6>
                          <p className="font-medium text-[#3E473F] text-sm">50</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Livestock</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Goats, chickens, and a small herd of cattle</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Farming methods</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Mainly conventional with minimal external inputs, some traditional organic practices</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Equipment</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Tractor, harvester, irrigation system</p>
                      </div>

                      <div className="grid grid-cols-2 gap-2 mt-3">
                          <div className="">
                              <h6 className="text-sm text-[#5F6D60]">Infrastructure</h6>
                              <p className="font-medium text-[#3E473F] text-sm">
                              Small barn for storage, basic cattle shelter, and a water well
                              </p>
                          </div>

                          <div className="">
                              <h6 className="text-sm text-[#5F6D60]">Size</h6>
                              <p className="font-medium text-[#3E473F] text-sm">1000 tonnes</p>
                          </div>

                      </div>

                  </div>

                </div>

                <div className="border  border-[#E1E6E1] bg-white rounded-lg mt-4">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                    Business Context
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                      
                      <div>
                          <h6 className="text-sm text-[#5F6D60]">Market connections</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Local village market, seasonal traders</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Distribution channels</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Sells directly at the weekly village market</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Processing capabilities</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Sun-drying grains and manual threshing of millet and sorghum</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Storage facilities</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Traditional granaries made of mud and thatch for grains</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Certifications held</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Relies on traditional practices and local knowledge</p>
                      </div>
                  </div>

                </div>


                <div className="border  border-[#E1E6E1] bg-white rounded-lg mt-4">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                    Knowledge and Experience
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                      
                      <div>
                          <h6 className="text-sm text-[#5F6D60]">Years of farming experience</h6>
                          <p className="font-medium text-[#3E473F] text-sm">20 years</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Technical expertise areas</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Crop rotation, livestock care, traditional pest management</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Training/education background</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Primary school level</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Technology adoption level</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Low—relies on traditional methods; no digital tools used</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Previous crop history</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Millet, sorghum, and groundnuts (minimal changes over the years due to reliance on hardy, drought-resistant crops)</p>
                      </div>
                  </div>

                </div>

            </div>

            <div>

              <div className="border  border-[#E1E6E1] bg-white rounded-lg">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                    Technical Parameters
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                      
                      <div>
                          <h6 className="text-sm text-[#5F6D60]">Soil type</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Sandy-loam, low in organic matter but suitable for millet and sorghum</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Conditions</h6>
                          <p className="font-medium text-[#3E473F] text-sm">pH around 6, moderate nutrient levels</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Water sources and availability</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Seasonal rainfall</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Growing season information</h6>
                          <p className="font-medium text-[#3E473F] text-sm">May to October (rainy season)</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Yield data</h6>
                          <p className="font-medium text-[#3E473F] text-sm">
                             Maize: ~1 ton per hectare  
                          </p>
                          <p className="font-medium text-[#3E473F] text-sm">
                          Cassava: ~1.5 tons per hectare
                          </p>
                          <p className="font-medium text-[#3E473F] text-sm">
                          Groundnuts: ~0.5 tons per hectare
                          </p>
                      </div>

                      <div className="mt-3">
                              <h6 className="text-sm text-[#5F6D60]">Chemical/fertilizer usage</h6>
                              <p className="font-medium text-[#3E473F] text-sm">
                              Rarely used due to cost; relies on organic manure
                              </p>
                          </div>

                  </div>

                </div>

                <div className="border  border-[#E1E6E1] bg-white rounded-lg mt-4">
                  <div className="flex gap-1 items-center py-3 px-3 bg-[#FFFFFF] rounded-t-lg">
                    <p className="font-medium text-sm text-[#3E473F]">
                    Agricultural Practices
                    </p>
                  </div>
                  <div className="bg-[#E1E6E1] h-[1px]"></div>

                  <div className="px-3 py-3 bg-white rounded-b-lg">
                      
                      <div>
                          <h6 className="text-sm text-[#5F6D60]">Cultivation techniques</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Crop rotation, intercropping</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Irrigation methods</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Nill</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Pest management approaches</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Manual pest removal and use of ash or neem leaves to deter pests naturally</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Soil management practices</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Soil enrichment, tillage</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Harvest practice</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Manual harvesting by family members, simple sorting on-site</p>
                      </div>

                      <div className="mt-3">
                          <h6 className="text-sm text-[#5F6D60]">Post-harvest practice</h6>
                          <p className="font-medium text-[#3E473F] text-sm">Sun-drying and storing grains in traditional woven baskets or sacks</p>
                      </div>
                  </div>

                </div>


            </div>
        </div>
      </>
      }

     {
        typeId === 2 &&
        <>

        <div className="flex gap-4">

          <div className="flex flex-col gap-2 py-4 w-full md:w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4">
              <p className="text-[#2E332F] text-sm">Avg. call duration (min)</p>

              <div>
                <h4 className="text-[#2E332F] font-semibold text-2xl">
                20
                </h4>

                <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
          </div>

          <div className="flex flex-col gap-2 py-4 w-full md:w-[264px] bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg px-4">
              <p className="text-[#2E332F] text-sm">Calls</p>

              <div>
                <h4 className="text-[#2E332F] font-semibold text-2xl">
                20
                </h4>

                <div className="mt-1">
                  <p className="text-primary text-sm font-medium">
                    30%{" "}
                    <span className="text-[#5F6D60] text-sm font-normal">
                      vs last month
                    </span>
                  </p>
                </div>
              </div>
          </div>

        </div>

        <div className="flex border mt-5 border-[#E1E6E1] rounded-lg w-full bg-white ">
          <div className="w-[25%] border-r border-l-[#E1E6E1] ">
            <div className="py-4 border-b border-b-[#E1E6E1] px-4 flex gap-2 items-center">
              <ForumOutlined style={{ color: "#2B8C34", fontSize: "22px" }} />
              <p className="font-medium text-sm leading-[18px] text-[#2E332F] ">
                Conversations Summary
              </p>
              <div className="text-[#2E332F] text-xs font-medium px-2 py-1 bg-[#ECEEEC] rounded-[4px]">
                120
              </div>
            </div>

            <div className="flex gap-3 items-center h-fit px-4 py-4">
              <div className="relative flex-grow">
                  <input
                    type="text"
                    className="appearance-none w-full placeholder:text-[#3E473F] 
                          placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] 
                          focus:outline-none focus:shadow-input rounded-lg border border-[#E1E6E1] bg-[#F6F7F6] py-3 px-10"
                    placeholder="Search"
                  />
                  <div className="absolute top-1/2 transform -translate-y-1/2 left-4 cursor-pointer">
                    <Search style={{ color: "#3E473F", fontSize: "15px" }} />
                  </div>
                
                </div>
                <div className="flex px-3 py-3 max-h-[46px] cursor-pointer justify-center items-center bg-[#F6F7F6] border border-[#E1E6E1] rounded-lg">
                <DateRange style={{ color: "#3E473F", fontSize: "20px" }} />

                </div>
            </div>

            <div className="flex flex-col overflow-y-auto h-[600px]">
              <div className={`p-4 bg-[#E1F7E3] border-b border-[#E1E6E1] `}>
                <p className="font-medium text-sm leading-[18px] text-[#2E332F] pb-1">
                  Irrigation & Water Management
                </p>
                <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                  Hello, I need help with watering my cro..
                </p>
                <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                  12:32 AM | 12 Aug 22
                </p>
              </div>

              <div className={`p-4 border-b border-[#E1E6E1]`}>
                <p className="font-medium text-sm leading-[18px] text-[#2E332F] pb-1">
                  Harvest Planning
                </p>
                <p className="font-normal text-sm leading-[20px] text-[#5F6D60] pb-[6px] truncate">
                  I'm excited to start planning for the upcoming harvest season.
                </p>
                <p className="font-normal text-sm leading-[16px] text-[#5C715E]">
                  12:32 AM | 12 Aug 22
                </p>
              </div>
            </div>
          </div>

          <div className="w-[75%] select_scroll">
            <>
              {/* EMPTY STATE */}
              {/* <div className="flex flex-col justify-center items-center h-full w-full">
              <img
                src={dialog}
                alt="dialog icon"
                className="w-[64px] h-[64px] pb-3 "
              />
              <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                Click on a summary to see the conversation
              </p>
            </div> */}
            </>
            <div className="border-b border-b-[#E1E6E1] flex flex-col gap-1 p-4 w-full ">
              <p className="font-medium text-sm leading-[18px] text-[#2E332F]">
                Irrigation & Water Management
              </p>
              <p
                className={`font-normal text-sm leading-[20px] text-[#5F6D60] ${
                  isToggleShowMore ? "" : "truncate"
                }`}
              >
                A smallholder farmer asked NHFA for irrigation advice due to dry
                conditions. NHFA suggested using a drip irrigation system,
                offering low-cost setup tips and water-saving techniques like
                rainwater collection and a solar pump. To assist further, NHFA
                connected the farmer with a local irrigation expert for technical
                support.
              </p>

              <p
                onClick={() => setIsToggleShowMore(!isToggleShowMore)}
                className="font-medium text-xs cursor-pointer leading-[16px] text-primary"
              >
                {isToggleShowMore ? "View less" : " Read more"}
              </p>
            </div>

            <div className="flex flex-col gap-[40px] my-4 p-4 overflow-y-auto w-full scroll h-[500px]">
              {/* SENDER */}
              <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                  Hello, I need help with watering my crops. It's been dry for
                  weeks.
                </p>
              </div>
              {/* RECEIVER */}
              <div className="flex justify-end items-end">
                <div className="rounded-lg border bg-[#F2FBF3] border-[#E1F7E3] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                  <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                    Hello! I can help you with irrigation tips. What crops are you
                    growing, and how large is your farm?
                  </p>
                </div>
              </div>
              {/* SENDER */}
              <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                  Hello, I need help with watering my crops. It's been dry for
                  weeks.
                </p>
              </div>
              {/* RECEIVER */}
              <div className="flex justify-end items-end">
                <div className="rounded-lg border bg-[#F2FBF3] border-[#E1F7E3] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                  <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                    Hello! I can help you with irrigation tips. What crops are you
                    growing, and how large is your farm?
                  </p>
                </div>
              </div>
              {/* SENDER */}
              <div className="rounded-lg border bg-[#F6F7F6] border-[#E1E6E1] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                  Hello, I need help with watering my crops. It's been dry for
                  weeks.
                </p>
              </div>
              {/* RECEIVER */}
              <div className="flex justify-end items-end">
                <div className="rounded-lg border bg-[#F2FBF3] border-[#E1F7E3] p-3 max-w-full lg:max-w-[500px] md:max-w-[400px] ">
                  <p className="font-normal text-sm leading-[18px] text-[#3E473F]">
                    Hello! I can help you with irrigation tips. What crops are you
                    growing, and how large is your farm?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }

    </div>
  );
};

export default FarmerProfile;
