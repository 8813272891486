import React from "react";
import { useNavigate } from "react-router-dom";

const OverviewTable = () => {
  const navigate = useNavigate();
  const farmer = {
    first_name: "Omotayo",
    last_name: "1500",
    phone_number: "07062198294",
  };
  //  const [farmers, setFarmers] = useState<
  //   Array<{
  //     country: string;
  //     crops: string;
  //     date_of_birth: Date | null;
  //     first_name: string;
  //     gender: string;
  //     language: string;
  //     last_name: string;
  //     livestock: string;
  //     phone_number: number | null;
  //     state: string;
  //   }>
  // >([
  //   {
  //     country: "",
  //     crops: "",
  //     date_of_birth: null,
  //     first_name: "",
  //     gender: "",
  //     language: "",
  //     last_name: "",
  //     livestock: "",
  //     phone_number: null,
  //     state: "",
  //   },
  //    ]);

  return (
    <div>
      {" "}
      <div className="overflow-x-auto border-x border-t border-t-[#E1E6E1]   border-x-[#E1E6E1] rounded-lg mt-5">
        <table className="bg-white rounded-lg w-full text-left">
          <thead className=" bg-[#F6F7F6]  rounded-lg">
            <tr className="">
              <th
                scope="col"
                className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
              >
                Topics
              </th>
              <th
                scope="col"
                className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
              >
                Call Volume
              </th>
              <th
                scope="col"
                className="text-[#5f6d60] px-4 py-4 text-sm font-medium leading-[18px]"
              >
                Breakdown
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Example row */}
            {/* {farmers?.map((farmer, index) => {
              return ( */}
            <tr className="border-b border-[#E1E6E1]">
              <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize ">
                {farmer?.first_name || "Harvesting Techniques"}
              </td>
              <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize">
                {farmer?.last_name || "Nill"}
              </td>
              <td className="px-4 py-4 text-[#3e473f] text-sm font-medium capitalize max-w-[180px]">
                <div className="flex flex-wrap gap-2">
                  <div
                    onClick={() =>
                      navigate(
                        `/call-insights/${"Best Crop Selection for Region"}`
                      )
                    }
                    className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer"
                  >
                    <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                      Best Crop Selection for Region (320)
                    </p>
                  </div>
                  <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                    <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                      Best Crop Selection for Region (320)
                    </p>
                  </div>
                  <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                    <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                      Optimal Planting Time (319)
                    </p>
                  </div>
                  <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                    <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                      Planting Density & Spacing (318)
                    </p>
                  </div>

                  <div className="border border-[#96A397] px-2 py-1 rounded-lg cursor-pointer">
                    <p className="text-[#5F6D60] text-xs font-medium leading-4 ">
                      Irrigation Techniques & Watering Needs (317)
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            {/* );
            })} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverviewTable;
