import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getFarmersByOrganization } from "../../redux/Farmers/farmersAction";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination";

const FarmersTable = () => {
  const dispatch = useAppDispatch();
  const { farmers, farmersLoading, farmersTotal } = useAppSelector(
    (state) => state.farmer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { profile } = useAppSelector((state) => state.auth);
  const [pageSize, setPageSize] = useState(10);

  // console.log(farmers, farmersLoading, profile);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (page: number) => {
    setPageSize(page);
  };

  useEffect(() => {
    dispatch(
      getFarmersByOrganization({
        id: profile?.organization?.id,
        page: currentPage,
      })
    );
  }, [dispatch, profile, currentPage]);

  return (
    <div>
      <div className="">
        <table className="bg-white rounded-lg mt-4 w-full text-left p-4 mb-3">
          <thead className="border bg-[#F6F7F6]  rounded-lg">
            <tr className="grid grid-cols-5 gap-4 p-4">
              <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                First Name
              </th>
              <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                Last Name
              </th>
              <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                Phone Number
              </th>

              <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                Country
              </th>
              <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                Created At
              </th>
              {/* <th className="text-[#5f6d60] text-sm font-medium leading-[18px]">
                State/Province
              </th> */}
            </tr>
          </thead>
          <tbody>
            {farmersLoading ? (
              <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
              </div>
            ) : (
              <>
                {farmers?.map((farmer, index) => {
                  return (
                    <Link to={`/farmer-profile/${farmer?.id}`}>
                      <tr
                        key={index}
                        className="grid grid-cols-5 gap-4 p-4 text-[#3e473f] text-sm font-medium leading-[18px] cursor-pointer"
                      >
                        <td className="">{farmer?.firstName || "Nill"}</td>
                        <td className="">{farmer?.lastName || "Nill"}</td>
                        <td className="">{farmer?.phoneNumber || "Nill"}</td>
                        <td className="">{farmer?.country || "Nill"}</td>
                        <td className="">
                          {moment(farmer.created).format("LLL")}
                        </td>

                        {/* <td className="">Lagos</td> */}
                      </tr>
                    </Link>
                  );
                })}

                <div>
                  <PaginationComponent
                    page={currentPage}
                    onPageChange={(page) => onPageChange(page)}
                    totalItems={farmersTotal}
                    pageSize={pageSize}
                    onPageSizeChange={(page) => onPageSizeChange(page)}
                    pageSizeShow
                  />
                </div>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FarmersTable;
