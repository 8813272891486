import { useAppDispatch, useAppSelector } from "../../../redux/store";
import GoBack from "../../../components/GoBack";
import VerificationInput from "react-verification-input";
import { handleSteps } from "../../../redux/Auth/Auth";
import { useState } from "react";
import { confirmationCode } from "../../../redux/Auth/authAction";

const StepTwo = () => {
  const dispatch = useAppDispatch();
  const { steps, email } = useAppSelector((state) => state.auth);
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (value: string) => {
    setVerificationCode(value);
  };

  const handleStepTwo = async () => {
    setLoading(true);
    const credentials = {
      email: email,
      code: verificationCode,
    };
    const res = await dispatch(confirmationCode(credentials));
    if (res.type !== "confirmationCode/rejected") {
      dispatch(handleSteps(3));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  return (
    <div>
      {" "}
      <div>
        <div className="flex w-full justify-between items-center pb-[24px]">
          <GoBack
            handleClick={() => {
              dispatch(handleSteps(1));
            }}
          />
          <p className="text-[#3E473F] text-sm font-semibold leading-[18px]">
            {steps}/4
          </p>
        </div>
        <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
          Verify your account
        </p>

        <p className="text-[#5F6D60] text-[16px] font-normal leading-5 mb-[32px] pt-3">
          We’ve sent a 6 digit code to your email address <br />{" "}
          <span className="text-[#181B19] font-semibold">name@company.com</span>
        </p>

        <div className="mb-6">
          <VerificationInput
            validChars="0-9"
            placeholder="0"
            classNames={{
              container: "verification-container",
              character: "verification-character",
              characterInactive: "character--inactive",
              characterSelected: "verification-character--selected",
              characterFilled: "character--filled",
            }}
            value={verificationCode}
            onChange={handleInputChange}
            inputProps={{ inputMode: "numeric" }}
          />
        </div>

        <button
          disabled={verificationCode.length !== 6 || loading ? true : false}
          onClick={
            verificationCode.length !== 6 || loading ? () => {} : handleStepTwo
          }
          className="outline-none mb-[32px] w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
        >
          {loading ? "Loading..." : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
