import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./ToolTip";

interface AreaChartProps {
  data: Record<string, any>[];
  height: number;
}

const InsightsChart: React.FC<AreaChartProps> = ({ data, height }) => {
  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#2B8C34" />
              <stop offset="1" stopColor="rgba(43, 140, 52, 0.00)" />
            </linearGradient>
          </defs>
          <CartesianGrid
            stroke="#F2F4F7"
            strokeDasharray="3 3"
            vertical={false}
          />

          <XAxis
            style={{
              fontSize: "12px",
              fontFamily: "Rethink Sans",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5F6D60",
            }}
            axisLine={false}
            tickLine={false}
            dataKey="name"
          />
          <YAxis
            style={{
              fontSize: "12px",
              fontFamily: "Rethink Sans",
              fontWeight: 400,
              lineHeight: "18px",
              color: "#5F6D60",
            }}
            axisLine={false}
            tickLine={false}
            dataKey="amt"
          />
          <Tooltip content={<CustomTooltip />} />

          <Area
            type="monotone"
            dataKey="amt"
            stroke="#2B8C34"
            strokeWidth={2}
            fillOpacity={0.1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InsightsChart;
